<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="网址名称">
                <a-input placeholder="请输入网址名称" v-model="queryParam.title" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="状态">
                <a-select
                  v-model="queryParam.status"
                  placeholder="状态"
                  allow-clear
                >
                  <a-select-option :key="-1" :value="-1">请选择</a-select-option>
                  <a-select-option :key="0" :value="0">启用</a-select-option>
                  <a-select-option :key="1" :value="1">禁用</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="addConfig()">添加</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="keyId"
        :columns="columns"
        :data="loadData"
      >
        <span slot="status" slot-scope="text, record">
          <a-tag color="#f50" v-if="record.status === 1">禁用</a-tag>
          <a-tag color="#87d068" v-else>启用</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="editForm(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确认要禁用此网址吗？" @confirm="delFormApi(record.keyId, 1)" v-if="record.status === 0">
            <a>禁用</a>
          </a-popconfirm>
          <a-popconfirm title="确认要启用此网址吗？" @confirm="delFormApi(record.keyId, 0)" v-else>
            <a>启用</a>
          </a-popconfirm>
        </span>
      </s-table>
      <add-config @ok="handleOk" ref="addconfig"></add-config>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { STable, Ellipsis } from '@/components'
import AddConfig from '@/views/webconfig/components/AddConfig'
import { configList, delConfig } from '@/api/websiteconfig'
const columns = [
  {
    title: '编号',
    dataIndex: 'keyId',
    width: '80px'
  },
  {
    title: '网址名称',
    dataIndex: 'title'
  },
  {
    title: '网址',
    dataIndex: 'websiteUrl'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    width: '100px'
  },
  {
    title: '排序',
    dataIndex: 'orderBy',
    width: '100px',
  },
  {
    title: '操作',
    width: '150px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'ConfigList',
  components: {
    AddConfig,
    STable,
    Ellipsis,
    STree
  },
  data () {
    this.columns = columns
    return {
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        title: '',
        type: 0,
        status: -1
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return configList(requestParameters)
          .then(res => {
            return res.result
          })
      }
    }
  },
  filters: {
  },
  created () {
  },
  methods: {
    resetSearchForm () {
      this.queryParam = {
        title: '',
        type: 0,
        status: -1
      }
      this.searcDate = []
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    delFormApi (id, status) {
      console.log(id)
      const _this = this
      delConfig({ keyId: id, status: status }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.$refs.table.refresh()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    addConfig () {
      const config = {
        keyId: 0,
        id: '',
        title: '',
        websiteUrl: '',
        type: 1,
        orderBy: 0
      }
      this.$refs.addconfig.ininData(config)
    },
    editForm (data) {
      this.$refs.addconfig.ininData(data)
    },
    handleOk (e) {
      console.log(e)
      this.$refs.table.refresh()
    }
  }
}
</script>
