<template>
  <a-modal
    title="添加/编辑网址"
    :width="800"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="dealStatusApi"
    @cancel="cancelForm"
  >
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="网址名称"
        :validateStatus="checkParams.titleStatus"
        :help="checkParams.titleStatusMsg"
      >
        <a-input
          style="width: 300px;"
          placeholder="请输入网址名称"
          v-decorator="['title', {initialValue: queryParam.title, rules: [{required: true, validator: titleCheck, validateTrigger: 'change'}]}]"
        />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="网址"
        :validateStatus="checkParams.websiteUrlStatus"
        :help="checkParams.websiteUrlStatusMsg"
      >
        <a-input
          style="width: 300px;"
          placeholder="请输入网址"
          v-decorator="['websiteUrl', {initialValue: queryParam.websiteUrl, rules: [{required: true, validator: urlCheck, validateTrigger: 'change'}]}]"
        />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="排序"
      >
        <a-input-number v-model="queryParam.orderBy" :min="0" :max="100000000" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { saveConfig } from '@/api/websiteconfig'
export default {
  name: 'AddConfig',
  components: {
  },
  props: {
    statusList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'DealMissionFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      visible: false,
      progress: 0,
      confirmLoading: false,
      missionStatus: 0,
      checkParams: {
        titleStatus: 'success',
        titleStatusMsg: null,
        websiteUrlStatus: 'success',
        websiteUrlStatusMsg: null
      },
      queryParam: {
        keyId: 0,
        id: '',
        title: '',
        websiteUrl: '',
        type: 1,
        orderBy: 0
      }
    }
  },
  created () {
  },
  methods: {
    ininData (data) {
      console.log('ininData', data)
      this.checkParams.missionStatusStatus = 'success'
      this.checkParams.missionStatusStatusMsg = null
      this.queryParam = data
      this.visible = true
    },
    titleCheck (rule, value, callback) {
      const _this = this
      _this.queryParam.title = value.trim()
      if (_this.queryParam.title !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入网址名称'
        callback(new Error('请输入网址名称'))
      }
    },
    urlCheck (rule, value, callback) {
      const _this = this
      _this.queryParam.websiteUrl = value.trim()
      if (_this.queryParam.websiteUrl !== '') {
        _this.checkParams.websiteUrlStatus = 'success'
        _this.checkParams.websiteUrlStatusMsg = null
      } else {
        _this.checkParams.websiteUrlStatus = 'error'
        _this.checkParams.websiteUrlStatusMsg = '请输入网址'
        callback(new Error('请输入网址'))
      }
    },
    cancelForm () {
      this.visible = false
    },
    dealStatusApi () {
      const _this = this
      if (_this.queryParam.title === '') {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入网址名称'
        return false
      } else {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      }

      if (_this.queryParam.websiteUrl === '') {
        _this.checkParams.websiteUrlStatus = 'error'
        _this.checkParams.websiteUrlStatusMsg = '请输入网址名称'
        return false
      } else {
        _this.checkParams.websiteUrlStatus = 'success'
        _this.checkParams.websiteUrlStatusMsg = null
      }
      _this.confirmLoading = true
      saveConfig(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.confirmLoading = false
          _this.$message.success('操作成功')
          _this.$emit('ok', 1)
          _this.cancelForm()
        } else {
          _this.confirmLoading = false
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.confirmLoading = false
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
