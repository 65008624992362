import request from '@/utils/request'
const baseURL = '/baseapi'
const websiteconfigApi = {
  ConfigList: '/admin/websiteconfig/list',
  ConfigInfo: '/admin/websiteconfig/configInfo',
  ConfigById: '/admin/websiteconfig/configById',
  SaveConfig: '/admin/websiteconfig/saveConfig',
  DelConfig: '/admin/websiteconfig/delConfig'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function configList (parameter) {
  return request({
    baseURL: baseURL,
    url: websiteconfigApi.ConfigList,
    method: 'post',
    data: parameter
  })
}

export function configInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: websiteconfigApi.ConfigInfo,
    method: 'post',
    data: parameter
  })
}

export function configById (parameter) {
  return request({
    baseURL: baseURL,
    url: websiteconfigApi.ConfigById,
    method: 'post',
    data: parameter
  })
}

export function saveConfig (parameter) {
  return request({
    baseURL: baseURL,
    url: websiteconfigApi.SaveConfig,
    method: 'post',
    data: parameter
  })
}

export function delConfig (parameter) {
  return request({
    baseURL: baseURL,
    url: websiteconfigApi.DelConfig,
    method: 'post',
    data: parameter
  })
}
